<template>
  <div class="index">
    <Nav />
    <TermsPage />
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
import TermsPage from "@/components/termsPage.vue";
export default {
  name: "Team",
  components: {
    Nav,
    TermsPage,
    Footer
  },
  metaInfo: {
    title: "SeismicCore | Terms & Privacy",
    meta: [
      { property: "og:title", content: "SeismicCore | Terms & Privacy" },
      {
        property: "og:description",
        content: "SeismicCore's Terms of Service & Privacy Policy."
      },
      {
        name: "description",
        content: "SeismicCore's Terms of Service & Privacy Policy.",
        id: "desc"
      },
      { name: "twitter:title", content: "SeismicCore | Terms & Privacy" },
      {
        name: "twitter:description",
        content: "SeismicCore's Terms of Service & Privacy Policy."
      }
    ]
  }
};
</script>
<style>
@import "../assets/css/main.css";
</style>
